.App {
  text-align: center;
  width: 600px;
  margin: 0 auto;

}
.btn-color {
  background-color: #5b8e0b !important;
  color: white !important;
}
/* .hading_color{
    background-color: #5B8E0B!important;
    color:white !important;
} */
.form {
  background-color: white;
  width: 70%;
  margin: auto;
  padding: 10px;
  /* border: 1px solid black; */
  padding-top: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
}
.add-btn {
  background-color: #ffa500 !important;
  color: white !important;
  padding: 10px;
  width: 10%;

  /* margin-left: 500px; */
}
.action-btn {
  width: auto;
  padding: 0px 6px;
  background-color: #ffa500 !important;
  color: white !important;
}

.upload-btn {
  background-color: #ffa500 !important;
  width: 100%;
  font-size: 16px;
  padding: 13px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-left: 20px;
  padding-right: 20px;
}
.box {
  background: white;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 38px 20px;
}
.text {
  font-size: 24px;
  color: #5b8e0b;
  padding: 38px 20px;
  text-align: center;
}

.input-box {
  /* font-size: 20px; */
  margin-bottom: 10px;
  text-align: end;
}
.select-box {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.select-box button {
  width: 100%;
}

.input-box p {
  display: none;
}

.download-csv button:hover{
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: none !important;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 50px;
  color: #649C0D;
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.pagination button {
  min-width: 30px;
}
.export_file_btn{ text-align: end;}
.export_file_btn button{
  background-color: #219d52;
  border: none;
  color: white;
  font-weight: 500;
  padding: 5px 20px;
  border-radius: 5px;
}