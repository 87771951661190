// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}



.background_main_box {
    background-image: url(../images/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
}


.background_main_box .css-3su884-MuiPaper-root {
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}


.logo_main img {
    width: 200px;
    margin-bottom: 25px;
}

.custum_btn {
    padding: 10px 0 !important;
    background-color: #7bbe11 !important;
}

.background_main_box .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid #7bbe11 !important;
}


.background_main_box .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #3b3b3b;
}

.header_logo {
    margin: 0px;
}

.box_dashboard .css-1wixbq1-MuiPaper-root-MuiCard-root {
    background-color: white !important;
}

.css-1wixbq1-MuiPaper-root-MuiCard-root {

    background-color: white !important;
    box-shadow: unset !important;
}

.css-1wixbq1-MuiPaper-root-MuiCard-root:before {
    content: unset !important;
}

.css-1wixbq1-MuiPaper-root-MuiCard-root:after {
    content: unset !important;
}

.css-c4cbym-MuiTypography-root {
    color: #649C0D;
    font-size: 16px;
}



.total_des_box {
    background-color: rgb(230, 255, 250) !important;
}


.meter_total_box {
    background-color: rgb(253, 237, 232) !important;
}

.user_box {
    background: #ecf2ff !important;
}

.dealer_box {
    background-color: rgb(254, 245, 229) !important;
}

.icon_box {
    width: 70px;
    height: 70px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 30px;
}

.icon_box svg {
    color: #649C0D;
    font-size: 20px;
}

.css-lneya7 {
    background-color: #f8f9fa;
}

.total_user_box p {
    color: rgb(93, 135, 255);
    margin-top: 14px;
    margin-bottom: 6px;
    font-size: 16px;

}

.value_user {
    color: rgb(93, 135, 255) !important;
}

.total_dealer_box p {
    color: rgb(255, 174, 31);
    margin-top: 14px;
    margin-bottom: 6px;
    font-size: 16px;
}


.total_distri_box p {
    color: rgb(19, 222, 185);
    margin-top: 14px;
    margin-bottom: 6px;
    font-size: 16px;
}

.total_active_inactive {
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
}

.main_meter_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Meee_acc {
    background-color: #eb7900;
    color: white;
    padding: 2px 15px;
    font-size: 12px;
    border-radius: 5px;
    font-weight: 300;
}

.meee_inac {

    background-color: #d9534f;
    color: white;
    padding: 2px 15px;
    font-size: 12px;
    border-radius: 5px;
}

.inac {
    margin-left: 15px;
}


.css-1hxl4af-MuiAvatar-root {
    color: #6da80e !important;
    background: #c1e090 !important;
}

.css-1dybbl5-MuiChip-label svg {
    color: #6da80e;
    stroke: #6da80e;
}

.meter_total_box p {
    color: rgb(235, 0, 0);
    font-size: 16px;
}


.css-ej28n0-MuiButtonBase-root-MuiChip-root[aria-controls="menu-list-grow"],
.css-ej28n0-MuiButtonBase-root-MuiChip-root:hover {
    border-color: unset;
    background: #6da80e !important;
    color: #eef2f6;
}

.empty_img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_img p {
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
}

.top_bar {
    BACKGROUND: #f7f7f7;
    MARGIN-BOTTOM: 60PX;
}

.css-lneya7 {
    margin-right: 10px;
}

.select-box .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border-width: unsset !important;
}



.edit_btn {
    background-color: transparent !important;
    flex: unset !important;
    border: none;
}

.edit_btn svg {
    color: #7b7b7b;
    font-size: 18px;
}

.edit_btn:hover svg {
    color: #649C0D;
}

.edit_btn:hover .btn_txt {
    color: #649C0D;
}

.btn_txt {
    color: #7b7b7b;
    font-size: 12px;
    line-height: 12px;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #659c0d7d !important;
    border-width: 2px;
}

label.Mui-focused {
    color: #649C0D !important;
}

.upload-btn {
    background-color: #eaeaea !important;
    padding: 11px 15px !important;
}



.user_box:after {
    position: unset;
    display: none;
}

.user_box:before {
    position: unset;
    display: none;
}

.dealer_box:after {
    position: unset;
    display: none;
}

.dealer_box:before {
    position: unset;
    display: none;
}

.total_des_box:after {
    position: unset;
    display: none;
}

.total_des_box:before {
    position: unset;
    display: none;
}

.meter_total_box:after {
    position: unset;
    display: none;
}

.meter_total_box:before {
    position: unset;
    display: none;
}









.log_box {
    background-color: #ecf2ff;
}




.value_user p {
    font-size: 26px;
    color: black;
}









.universal_box {
    background-color: white !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.universal_box:after {
    position: unset;
}

.universal_box:before {
    position: unset;
}

.universal_box p {
    color: #649c0d;
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
}

.universal_box:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.box_main_cnt {
    color: black;
}

.inner_card_box {
    background-color: #ebf7d9;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.inner_card_box svg {
    color: #649C0D;
    font-size: 30px;
}

.inner_sub_details {
    color: #111111;
    font-size: 22px;
    margin-top: 10px;
    font-weight: 500;
}

.lanloard_box { background-color: white;      border-radius: 20px; box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px; } 

.lanloard_box h2{ font-size: 20px;
    background: #649c0d;
    padding: 10px 15px;
    color: white;
    border-radius: 10px 10px 0px 0px; font-weight: 400;}
.divide_box{ border-radius:0px; overflow: hidden;}

.lanloard_box ul li { list-style: none; padding: 10px 0; color: #649C0D; font-weight: 500;}

.lanloard_box ul li span{ color: black;}

.lanloard_box ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }


  .pageInation_box{ margin-top: 35px;}
.pageInation_box ul{ justify-content: center;}



@media only screen and (max-width: 1400px) {

    .inner_sub_details {

        font-size: 18px;
        margin-top: 5px;

    }

    .icon_box {
        margin-right: 0px;
        width: 55px;
    }
}




@media only screen and (max-width: 1000px) {

    .lanloard_box ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
      }

}



@media only screen and (max-width: 575px) {
    .icon_box {

        margin-right: 25px;
    }

}

